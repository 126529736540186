import React, {Component} from 'react';
import {renderToStaticMarkup} from 'react-dom/server';
import {withLocalize, Translate} from 'react-localize-redux';
import {Redirect, withRouter} from "react-router-dom";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import {Icon, Paper, Typography} from "@material-ui/core";
import {SelectValidator, TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import log from "../../utils/logger";
import InputAdornment from "@material-ui/core/InputAdornment/InputAdornment";
import IconButton from "@material-ui/core/IconButton/IconButton";
import LoadingButton from "../../utils/LoadingButton";
import connect from "react-redux/es/connect/connect";
import moment from "moment";
import DatePickerDialog from "../../utils/DatePickerDialog";
import * as PropTypes from "prop-types";
import * as Actions from "../../redux/actions";
import FormLabel from "@material-ui/core/FormLabel/FormLabel";
import FormControl from "@material-ui/core/FormControl/FormControl";
import FormGroup from "@material-ui/core/FormGroup/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Radio from "@material-ui/core/Radio/Radio";
import FormHelperText from "@material-ui/core/FormHelperText/FormHelperText";
import RadioGroup from "@material-ui/core/RadioGroup/RadioGroup";

const mapStateToProps = (state, props) => {
    return {
        account: state.account,
        settings: state.settings,
        regions: state.regions,
        openingConnectionGraph: state.openingConnectionGraph,
        openingConnectionSearchFormMessage: state.openingConnectionSearchFormMessage
    }
};

const mapDispatchToProps = dispatch => {
    return {
        searchGraph: (values) => {
            dispatch(Actions.http.connection.openings.graph(values));
        },
        searchFormMessage: (values) => {
            dispatch(Actions.http.connection.openings.searchFormMessage(values))
        }
    }
};

class ReserveForm extends Component {
    constructor(props) {
        super(props);

        let item_type = "all";
        let region_id = undefined;
        let area_id = undefined;
        let startMoment = moment();
        let endMoment = moment();

        if(props.regions.payload.length !== 0){
            region_id = props.regions.payload[0].region_id
        }

        if (typeof props.searchData !== "undefined") {
            item_type = props.searchData.item_type === "" ? "all" : props.searchData.item_type;
            region_id = props.searchData.region_id;
            area_id = props.searchData.area_id;
            startMoment = moment(props.searchData.date_start);
            endMoment = moment(props.searchData.date_end);
        }

        this.state = {
            account: props.account.payload,
            item_type,
            region_id,
            area_id,
            startMoment,
            endMoment,
            canOverDay: props.settings.payload.use_multi_day_reservation,
            loading: false,
            formMessage: "",
            formMessageEn: ""
        };
        this.searchFormMessage();
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            loading: nextProps.openingConnectionGraph.meta.fetch,
        });
        if (nextProps.openings !== this.props.openings) {
            this.setState({
                openings: nextProps.openings.payload,
            })
        }
        if (nextProps.openingConnectionGraph !== this.props.openingConnectionGraph) {
            if (nextProps.openingConnectionGraph.meta.status === Actions.statusEnum.SUCCESS) {
                this.props.handleNext();
            }
        }
        if (nextProps.openingConnectionSearchFormMessage !== this.props.openingConnectionSearchFormMessage) {
            if (nextProps.openingConnectionSearchFormMessage.meta.status === Actions.statusEnum.SUCCESS) {
                this.setState({
                    formMessage: nextProps.openingConnectionSearchFormMessage.payload.result.opening_time_announcement,
                    formMessageEn: nextProps.openingConnectionSearchFormMessage.payload.result.opening_time_announcement_en
                })
            }
        }
        this.setState({
            account: nextProps.account.payload
        })
    }

    handleChange = name => event => {
        log.debug(event.target.value);
        if (name === "region_id") {
            this.setState({
                area_id: undefined
            })
            this.searchFormMessage(event.target.value);
        }
        this.setState({
            [name]: event.target.value,
        });
    };


    handleSearch = (e) => {
        if (e) {
            e.preventDefault();
        }
        const {region_id, area_id, startMoment, endMoment, item_type} = this.state;
        let item_type_value;
        if (item_type !== "all") {
            item_type_value = item_type;
        } else {
            item_type_value = ""
        }
        const values = {
            item_type: item_type_value,
            region_id,
            area_id,
            date_start: startMoment.format("YYYY-MM-DD"),
            date_end: this.state.canOverDay ? endMoment.format("YYYY-MM-DD") : startMoment.format("YYYY-MM-DD"),
        }
        this.props.saveSearchData(values);
        this.props.searchGraph(values);
    };

    searchFormMessage = (value) => {
        this.props.searchFormMessage({
            region_id: value ? value : this.state.region_id
        })
    };


    /**
     * カレンダーのstart分disable分
     * @param current
     * @returns {boolean}
     */
    disabledStartDate = (current) => {
        return current < this.state.startMoment.startOf('day') || this.state.endMoment.endOf("day") < current;
    };

    /**
     * カレンダーのend分disableする
     */
    disabledEndDate = (current) => {
        return current < this.state.startMoment.startOf('day');
    };


    render() {
        const {translate} = this.props;
        const regionSelectItems = this.props.regions.payload.map((value, index) => {
            return <MenuItem key={value.region_id} value={value.region_id}>{value.region_name}</MenuItem>
        });
        let initialArea = undefined;
        let selectAreas = [];
        if (this.state.region_id) {
            this.props.regions.payload.forEach((value, index) => {
                if (value.region_id === this.state.region_id) {
                    selectAreas = value.areas;
                }
            });
            selectAreas = selectAreas.filter((value, index) => {
                if (value.area_all_flag === true) {
                    initialArea = value.area_id;
                }
                let ignore_delete_flag = false;
                let ignore_visibility_flag = false;
                if(this.state.account.hasOwnProperty("account_region_authorities")){
                    this.state.account.account_region_authorities.forEach((value) => {
                        if(value.region_id === this.state.region_id){
                            ignore_delete_flag = value.account_region_authority_ignore_area_delete_flag;
                            ignore_visibility_flag =  value.account_region_authority_ignore_area_visibility;
                        }
                    });
                }
                if (this.state.item_type === "1") {
                    return value.seats_flag && (!value.area_delete_flag || ignore_delete_flag) && (value.area_visible_flag || ignore_visibility_flag);
                } else if (this.state.item_type === "2") {
                    return value.equipments_flag && (!value.area_delete_flag || ignore_delete_flag) && (value.area_visible_flag || ignore_visibility_flag);
                } else {
                    return (!value.area_delete_flag || ignore_delete_flag) && (value.area_visible_flag || ignore_visibility_flag);
                }
            });
        }

        return (
            <div className={"reserve-form"}>
                <Paper className={"paper"}>
                    <ValidatorForm
                        onError={errors => log.debug(errors)}
                        onSubmit={this.handleSearch}>
                        <SelectValidator
                            fullWidth
                            id="region_id"
                            label={`${translate("region")} *`}
                            helperText={translate("region_placeholder")}
                            placeholder={translate("region_placeholder")}
                            margin="normal"
                            variant="outlined"
                            name="region_id"
                            validators={['required']}
                            errorMessages={[translate("account_id_placeholder")]}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={this.state.region_id}
                            onChange={this.handleChange("region_id")}
                        >
                            {regionSelectItems}
                        </SelectValidator>


                        <SelectValidator
                            fullWidth
                            id="area_id"
                            label={`${translate("area")} *`}
                            helperText={translate("area_placeholder")}
                            placeholder={translate("area_placeholder")}
                            margin="normal"
                            variant="outlined"
                            name="area_id"
                            validators={['required']}
                            errorMessages={[translate("area_placeholder")]}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={this.state.area_id ? this.state.area_id: initialArea}
                            onChange={this.handleChange("area_id")}
                        >
                            {(() => {
                                return selectAreas.map((value, index) => {
                                    if (this.props.activeLanguage.code === "jp") {
                                        return <MenuItem key={value.area_id}
                                                         value={value.area_id}>{value.area_name}</MenuItem>
                                    } else {
                                        return <MenuItem key={value.area_id}
                                                         value={value.area_id}>{value.area_name_en}</MenuItem>
                                    }
                                });
                            })()}
                        </SelectValidator>

                        <FormControl component="fieldset"
                                     className={"common-radio-form-control"}
                                     variant="outlined">
                            <FormLabel component="span"
                                       className={"common-radio-form-label"}>{translate("reserve_type")}</FormLabel>
                            <RadioGroup
                                row
                                className={"common-radio-group"}
                                name="position"
                                value={this.state.item_type}
                                onChange={this.handleChange("item_type")}>
                                <FormControlLabel
                                    control={
                                        <Radio color="primary"/>
                                    }
                                    value={"all"}
                                    label={translate("all_name")}
                                />
                                <FormControlLabel
                                    control={
                                        <Radio color="primary"/>
                                    }
                                    value={"1"}
                                    label={translate("place_name")}
                                />
                                <FormControlLabel
                                    control={
                                        <Radio color="primary"/>
                                    }
                                    value={"2"}
                                    label={`${translate("equipment_name")}`}
                                />
                            </RadioGroup>
                            <FormHelperText>{translate("reserve_type_placeholder")}</FormHelperText>
                        </FormControl>

                        <div className={"reserve-form-date"}>
                            <TextValidator
                                onClick={() => this.setState({
                                    isOpenStartDateDialog: true
                                })}
                                className={"full-width"}
                                id="account_id"
                                label={translate("reserve_search_date_start")}
                                helperText={this.state.idHelper}
                                placeholder={translate("reserve_search_date_start_placeholder")}
                                margin="normal"
                                variant="outlined"
                                name="account_id"
                                validators={['required']}
                                errorMessages={[translate("reserve_search_date_start_placeholder")]}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                InputProps={{
                                    readOnly: true,
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton>
                                                <Icon color="primary">
                                                    calendar_today
                                                </Icon>
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                                value={this.state.startMoment.format("YYYY-MM-DD")}
                            />

                            {this.state.canOverDay ?
                                <TextValidator
                                    onClick={() => this.setState({
                                        isOpenEndDateDialog: true
                                    })}
                                    className={"full-width left-gutter"}
                                    id="end_date"
                                    label={translate("reserve_search_date_end")}
                                    helperText={translate("reserve_search_date_end_placeholder")}
                                    placeholder={translate("reserve_search_date_end_placeholder")}
                                    margin="normal"
                                    variant="outlined"
                                    name="account_id"
                                    validators={['required']}
                                    errorMessages={[translate("reserve_search_date_end_placeholder")]}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        readOnly: true,
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton>
                                                    <Icon color="primary">
                                                        calendar_today
                                                    </Icon>
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                    value={this.state.endMoment ?
                                        this.state.endMoment.format("YYYY-MM-DD")
                                        :
                                        ""
                                    }
                                />
                                :
                                null
                            }
                        </div>

                        <Typography color={"secondary"} variant="caption" gutterBottom >
                            {this.props.activeLanguage.code === "jp" ?
                                this.state.formMessage.split('\n').map((item, key) => {
                                    return <span key={key}>{item}<br/></span>
                                })
                                :
                                this.state.formMessageEn.split('\n').map((item, key) => {
                                    return <span key={key}>{item}<br/></span>
                                })
                            }

                        </Typography>

                        <LoadingButton
                            variant={"contained"}
                            type={"submit"}
                            fullWidth
                            color={"primary"}
                            loading={this.state.loading}>
                            <Icon className={"right-gutter"}>search</Icon>
                            {translate("search")}
                        </LoadingButton>

                    </ValidatorForm>
                </Paper>

                <DatePickerDialog
                    disablePast={true}
                    selectedDate={this.state.startMoment}
                    open={this.state.isOpenStartDateDialog}
                    handleClose={() => {
                        this.setState({
                            isOpenStartDateDialog: false
                        })
                    }}
                    handleAction={(date) => {
                        this.setState({
                            startMoment: date,
                            endMoment: undefined,
                            isOpenStartDateDialog: false
                        })
                    }}
                />
                <DatePickerDialog
                    selectedDate={this.state.endMoment}
                    disabledDate={this.disabledEndDate}
                    open={this.state.isOpenEndDateDialog}
                    handleClose={() => {
                        this.setState({
                            isOpenEndDateDialog: false
                        })
                    }}
                    handleAction={(date) => {
                        this.setState({
                            endMoment: date,
                            isOpenEndDateDialog: false
                        })
                    }}
                />
            </div>
        );
    }
}


ReserveForm.propTypes = {
    handleBack: PropTypes.func,
    handleNext: PropTypes.func
};

ReserveForm.defaultProps = {
    handleBack: () => {

    },
    handleNext: () => {

    }
};


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withLocalize(ReserveForm)));