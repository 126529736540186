import React from 'react'
import ReactDOM from 'react-dom';
import {connect, store} from 'react-redux'
import {instanceOf} from 'prop-types';
import {withRouter, Link} from "react-router-dom";
import log from 'utils/logger';
import Header from 'containers/header/Header';
import {withLocalize, Translate} from 'react-localize-redux';
import moment from 'moment';
import 'moment/locale/ja';
import * as Actions from "redux/actions";
import Stepper from "@material-ui/core/es/Stepper/Stepper";
import Step from "@material-ui/core/es/Step/Step";
import StepButton from "@material-ui/core/StepButton/StepButton";
import {Icon} from "@material-ui/core";
import StepLabel from "@material-ui/core/es/StepLabel/StepLabel";
import NotFound from "containers/notfound/NotFound";
import DialBox from "utils/DialBox";
import IntervalTimer from 'react-interval-timer';
import Button from "@material-ui/core/Button/Button";
import LoadingButton from "../../utils/LoadingButton";
import {ValidatorForm} from "react-material-ui-form-validator";
import LoadingOverlay from "react-loading-overlay";

moment.locale('ja');

const mapStateToProps = (state, props) => {
    return {
        login: state.login,
        signupConnection: state.signupConnection
    }
}
const mapDispatchToProps = dispatch => {
    return {
        postSignUp: (data) => {
            dispatch(Actions.http.connection.users.signup(data))
        },
        setSuccessMessage: (message) => {
            dispatch(Actions.data.success({
                message
            }, true))
        },
        setFailureMessage: (message) => {
            dispatch(Actions.data.failure({
                message
            },true))
        },
        doLogout() {
            dispatch(Actions.http.connection.authentication.logout());
        }
    }
}

class Signup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            activeStep:0,
            new_passcode: "",
            confirm_passcode: "",
            signupConnection: props.signupConnection,

            printObject: {}
        }
    }

    static getDerivedStateFromProps(nextProps, state) {
        let value = {}
        value.loading = nextProps.signupConnection.meta.fetch;

        if (nextProps.signupConnection !== state.signupConnection) {
            value.signupConnection = nextProps.signupConnection;
            if (nextProps.signupConnection.meta.status === Actions.statusEnum.SUCCESS) {
                value.printObject = nextProps.signupConnection.payload.result.print_data;
                value.activeStep = state.activeStep + 2;
            }
        }
        if (0 === Object.keys(value).length) {
            return null;
        }
        return value;
    }

    componentDidUpdate() {
        if (this.state.activeStep > 3 && !this.state.showLogoutButton) {
            window.printObject = this.state.printObject;
            document.location.href = 'signupprint://signupprint';
        }
    }

    viewPasscodeMask = (isConfirm) => {
        let mask;
        let checkPasscode = this.state.new_passcode;
        if (isConfirm) {
            checkPasscode = this.state.confirm_passcode;
        }
        switch (checkPasscode.length) {
            case 0:
                mask = "○○○○"
                break;
            case 1:
                mask = "●○○○"
                break;
            case 2:
                mask = "●●○○"
                break;
            case 3:
                mask = "●●●○"
                break;
            case 4:
                mask = "●●●●"
                break;
            default:
                mask = "○○○○"
                ;
        }
        return (
            mask
        )
    };


    getStepContent = (stepIndex) => {
        const {translate} = this.props;
        switch (stepIndex) {
            case 0:
                return (
                    <div className="sign-up-wrapper">
                        <div className="navigation-message-big">
                            <span>
                                <Icon style={{fontSize: 100, margin: 10}}>
                                    person_add
                                </Icon>
                                <br/>
                                {translate("sign_up_registration_message_1")}
                                <br/>
                                {translate("sign_up_registration_message_2")}
                            </span>

                        </div>
                        <Button
                            className={"half-width"}
                            variant={"contained"}
                            type={"submit"}
                            color={"primary"}
                            onClick={() => {
                                this.setState({
                                    activeStep: stepIndex + 1
                                })
                            }}
                        >
                            {translate("sign_up_step_1")}
                        </Button>
                    </div>
                )
            case 1:
                return (
                    <div className="sign-up-wrapper">
                        <div className="navigation-message">
                            <span>{translate("sign_up_step_2")}</span>
                        </div>
                        <div
                            className="passcode-input-view">
                            <span className={"number"}>
                                {this.viewPasscodeMask(false)}
                            </span>
                        </div>
                        <DialBox
                            handleInput={(value) => {
                                let passcode = this.state.new_passcode + value;
                                if (passcode.length === 4) {
                                    this.setState({
                                        new_passcode: this.state.new_passcode + value,
                                        activeStep: stepIndex + 1
                                    })
                                } else {
                                    this.setState({
                                        new_passcode: this.state.new_passcode + value
                                    })
                                }
                            }}
                            handleBackSpace={() => {
                                this.setState({
                                    new_passcode: this.state.new_passcode.slice(0, -1)
                                })
                            }}
                            handleReset={() => {
                                this.setState({new_passcode: ""})
                            }}
                        />
                    </div>

                )
            case 2:
                return (
                    <div>
                        <div className="navigation-message">
                            <span>{translate("sign_up_step_3")}</span>
                        </div>
                        <div
                            className="passcode-input-view">
                            <span className={"number"}>
                                {this.viewPasscodeMask(true)}
                            </span>
                        </div>
                        <DialBox
                            handleInput={(value) => {
                                let passcode = this.state.confirm_passcode + value;
                                if (passcode.length === 4) {
                                    let passcode = this.state.confirm_passcode + value;
                                    if(passcode === this.state.new_passcode){
                                        this.setState({
                                            confirm_passcode: passcode,
                                        }, () => {
                                            this.props.postSignUp({
                                                account_passcode: passcode
                                            })
                                        })
                                    }else{
                                        this.props.setFailureMessage(
                                            `${translate("not_confirm_passcode")}。${translate("change_pass_message_2")}。`
                                        )
                                        this.setState({
                                            new_passcode: "",
                                            confirm_passcode: "",
                                            activeStep: 1
                                        })
                                    }
                                } else {
                                    this.setState({
                                        confirm_passcode: this.state.confirm_passcode + value
                                    })
                                }
                            }}
                            handleBackSpace={() => {
                                this.setState({
                                    confirm_passcode: this.state.confirm_passcode.slice(0, -1)
                                })
                            }}
                            handleReset={() => {
                                this.setState({confirm_passcode: ""})
                            }}
                        />
                    </div>
                )

            case 3:
            default:
                return (
                    <div className="sign-up-wrapper">
                        <IntervalTimer
                            timeout={15000}
                            callback={() => {
                                this.props.doLogout();
                                this.setState({
                                    showLogoutButton: true
                                })
                            }}
                            enabled={true}
                            repeat={false}
                        />
                        <div className="navigation-message-big">
                            <Icon style={{fontSize: 100, margin: 10}}>
                                check_circle
                            </Icon>
                            <br/>
                            <span>{translate("sign_up_registration_message_5")}<br/>
                                <strong>{translate("sign_up_registration_message_6")}</strong><br/>
                                {translate("sign_up_registration_message_7")}
                            </span>
                        </div>
                        {this.state.showLogoutButton ?
                            <Button
                                className={"half-width"}
                                variant={"contained"}
                                type={"submit"}
                                color={"secondary"}
                                onClick={() => {
                                    this.props.doLogout()
                                }}
                            >
                                {translate("logout")}
                            </Button>
                            :
                            null
                        }
                    </div>
                );
        }
    };


    render() {
        const {activeStep} = this.state;
        const {translate} = this.props;
        const formItemLayout = {
            colon: false,
            layout: "inline"
        };
        return (
            <div className="App">
                <Header
                    title={translate("change_passcode")}
                    showLogoutButton={true}
                    showMenuButton={false}
                />

                <LoadingOverlay
                    active={this.state.loading}
                    spinner
                    style={{
                        wrapper:{
                            overflow: this.props.active ? 'hidden' : 'scroll'
                        }
                    }}
                >
                <div className={"Container with-footer with-stepper"}>
                    <Stepper activeStep={activeStep} alternativeLabel>
                        <Step key={1}>
                            <StepButton
                                onClick={() => {
                                    this.setState({
                                        activeStep: 0,
                                        new_passcode: ""
                                    })
                                }}
                                icon={
                                    activeStep === 0 ?
                                        <Icon color={"primary"}>
                                            perm_identity
                                        </Icon>
                                        : 1
                                }
                                disabled={activeStep === 4 || activeStep < 1}
                            >
                                {translate("sign_up_step_1")}
                            </StepButton>
                        </Step>
                        <Step key={2}>
                            <StepButton
                                icon={
                                    activeStep > 1 ?
                                        2 :
                                        <Icon color={activeStep >= 1 ? "primary" : "disabled"}>lock</Icon>
                                }
                                onClick={() => {
                                    this.setState({
                                        activeStep: 1,
                                        new_passcode: ""
                                    })
                                }}
                                disabled={activeStep === 4 || activeStep < 2}
                            >
                                {translate("new_passcode")}
                            </StepButton>
                        </Step>

                        <Step key={3}>
                            <StepButton
                                icon={
                                    activeStep > 2 ?
                                        3 :
                                        <Icon color={activeStep >= 2 ? "primary" : "disabled"}>lock</Icon>
                                }
                                onClick={() => {
                                    this.setState({
                                        activeStep: 1,
                                        confirm_passcode: ""
                                    })
                                }}
                                disabled={activeStep === 4 || activeStep < 3}
                            >
                                {translate("confirm_passcode")}
                            </StepButton>
                        </Step>
                        <Step key={3}>
                            <StepLabel icon={<Icon
                                color={activeStep >= 3 ? "primary" : "disabled"}>done_outline</Icon>}>
                                {translate("change_pass_step_4")}
                            </StepLabel>
                        </Step>
                    </Stepper>


                    <div>
                        {this.getStepContent(activeStep)}
                    </div>

                </div>
                </LoadingOverlay>
            </div>
        );
    }
}

Signup.propTypes = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withLocalize(withRouter(Signup)));